import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 3264.000000 3264.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd"  transform="translate(0.000000,3264.000000) scale(0.100000,-0.100000)">



<path d="M15780 27984 c-1075 -28 -2028 -175 -3025 -468 -1047 -307 -2189
-848 -3095 -1464 -682 -464 -1192 -890 -1765 -1473 -536 -545 -943 -1048
-1370 -1694 -882 -1334 -1475 -2854 -1730 -4431 -34 -215 -32 -200 -54 -374
-69 -535 -90 -948 -90 -1755 0 -871 27 -1322 115 -1935 38 -266 119 -700 168
-905 3 -11 19 -78 37 -150 158 -660 404 -1373 686 -1995 783 -1719 1958 -3197
3451 -4339 763 -583 1619 -1080 2487 -1443 856 -358 1726 -607 2615 -748 342
-54 528 -75 1030 -116 256 -20 1434 -31 1810 -15 1117 45 2115 223 3115 556
1067 355 1968 802 2893 1434 1035 707 2004 1651 2748 2676 261 359 528 777
729 1140 15 28 48 86 72 130 82 147 308 608 397 810 483 1095 796 2269 911
3420 87 876 87 2104 -1 2970 -142 1414 -583 2864 -1255 4127 -645 1213 -1465
2260 -2484 3169 -731 653 -1580 1231 -2435 1659 -1221 611 -2440 977 -3796
1140 -163 20 -423 41 -744 60 -173 10 -1177 20 -1420 14z m957 -334 c183 -6
338 -12 345 -15 7 -2 92 -9 188 -15 96 -5 216 -15 265 -21 292 -35 621 -83
840 -123 44 -8 105 -19 135 -25 30 -5 98 -19 150 -31 52 -11 115 -25 140 -30
44 -10 118 -27 230 -54 62 -15 338 -89 415 -110 481 -136 1063 -352 1605 -597
88 -40 643 -317 715 -357 155 -86 253 -143 378 -218 77 -46 143 -84 147 -84 5
0 10 -4 12 -8 2 -4 32 -26 68 -47 85 -51 398 -262 536 -362 60 -43 122 -87
137 -98 25 -18 55 -41 167 -125 650 -489 1413 -1231 1918 -1864 31 -39 59 -73
63 -76 3 -3 34 -43 69 -90 35 -47 66 -87 69 -90 8 -7 92 -118 114 -150 28 -40
87 -123 108 -150 23 -29 199 -283 214 -308 6 -9 36 -56 67 -103 64 -97 338
-546 338 -554 0 -3 11 -22 23 -43 82 -129 309 -576 444 -872 14 -30 36 -78 49
-106 13 -28 24 -54 24 -57 0 -3 14 -35 30 -70 17 -36 30 -68 30 -70 0 -3 13
-34 29 -69 16 -35 37 -85 46 -113 10 -27 22 -54 27 -59 4 -6 8 -17 8 -25 0 -9
6 -27 13 -41 8 -14 28 -68 47 -120 18 -52 37 -99 41 -105 5 -5 9 -16 9 -25 0
-8 7 -28 15 -44 8 -15 15 -36 15 -45 0 -9 6 -28 14 -43 8 -15 17 -41 21 -59 4
-19 11 -40 15 -49 10 -18 28 -72 70 -210 17 -58 36 -114 40 -125 5 -11 13 -40
20 -65 6 -25 16 -63 21 -85 6 -22 14 -49 19 -60 8 -18 22 -71 79 -305 12 -47
26 -98 31 -115 5 -16 12 -50 16 -75 4 -25 10 -49 14 -55 4 -5 11 -37 15 -70 4
-33 10 -64 13 -70 4 -5 12 -39 19 -75 6 -36 19 -103 28 -150 86 -442 141 -856
164 -1240 6 -91 16 -205 22 -255 16 -122 16 -1167 0 -1290 -6 -49 -16 -164
-22 -255 -22 -345 -76 -786 -134 -1085 -9 -44 -22 -116 -30 -160 -8 -44 -21
-111 -30 -150 -8 -38 -21 -101 -29 -140 -8 -38 -22 -99 -31 -135 -9 -36 -20
-83 -25 -105 -8 -36 -48 -191 -87 -335 -8 -30 -20 -76 -26 -102 -7 -25 -15
-50 -20 -54 -4 -4 -7 -15 -7 -24 0 -17 -35 -139 -50 -175 -4 -11 -36 -105 -70
-210 -33 -104 -65 -199 -69 -210 -5 -11 -30 -78 -56 -150 -26 -71 -51 -139
-55 -150 -5 -11 -19 -45 -30 -75 -12 -30 -26 -64 -31 -75 -4 -11 -31 -74 -58
-141 -27 -66 -62 -147 -76 -180 -15 -32 -33 -75 -42 -94 -85 -196 -361 -743
-434 -863 -16 -26 -29 -49 -29 -52 0 -2 -24 -44 -54 -93 -29 -49 -68 -115 -86
-148 -36 -64 -98 -162 -228 -362 -45 -70 -85 -134 -89 -142 -4 -8 -11 -19 -15
-25 -28 -33 -98 -136 -98 -144 0 -5 -3 -11 -7 -13 -5 -1 -24 -25 -43 -53 -47
-68 -352 -479 -361 -485 -3 -3 -49 -57 -100 -121 -94 -116 -268 -322 -329
-389 -18 -19 -73 -80 -123 -135 -254 -278 -737 -742 -1012 -970 -33 -28 -65
-54 -71 -60 -37 -33 -74 -64 -114 -95 -25 -19 -47 -37 -50 -40 -17 -20 -434
-334 -526 -398 -11 -7 -41 -29 -69 -48 -116 -83 -464 -309 -650 -420 -263
-158 -392 -234 -399 -234 -4 0 -25 -12 -48 -26 -40 -26 -467 -241 -598 -302
-207 -96 -250 -116 -295 -133 -27 -11 -59 -24 -70 -29 -61 -28 -285 -117 -455
-180 -107 -40 -204 -76 -215 -81 -37 -16 -510 -171 -560 -184 -27 -7 -72 -20
-100 -28 -139 -40 -477 -128 -590 -152 -44 -9 -107 -23 -140 -31 -33 -7 -98
-20 -145 -29 -47 -9 -103 -21 -125 -26 -74 -17 -441 -77 -585 -95 -257 -33
-455 -53 -634 -64 -87 -6 -162 -13 -167 -16 -5 -3 -174 -9 -376 -13 -397 -9
-974 0 -1143 18 -58 6 -170 16 -250 22 -289 22 -489 47 -840 104 -205 34 -187
30 -525 100 -85 17 -187 40 -245 55 -19 5 -73 19 -120 31 -125 31 -232 59
-355 94 -33 9 -78 21 -100 27 -22 5 -47 13 -55 17 -9 5 -31 12 -50 16 -19 4
-41 11 -50 15 -8 4 -100 36 -205 69 -104 34 -198 66 -207 71 -10 6 -23 10 -29
10 -6 0 -25 6 -42 14 -18 7 -66 26 -107 41 -106 39 -251 95 -275 105 -11 5
-108 45 -215 90 -192 81 -234 100 -460 207 -155 74 -207 100 -330 165 -92 48
-153 81 -325 178 -22 12 -65 37 -96 56 -31 19 -59 34 -61 34 -7 0 -255 151
-338 205 -36 23 -119 77 -185 120 -221 144 -642 447 -795 574 -31 25 -76 62
-100 81 -24 19 -64 52 -89 74 -25 21 -55 45 -66 55 -54 44 -212 178 -225 191
-9 8 -63 58 -121 110 -248 226 -576 556 -795 800 -27 30 -51 57 -55 60 -3 3
-47 55 -99 115 -51 61 -96 112 -100 115 -28 22 -488 618 -568 736 -6 11 -35
53 -64 94 -80 117 -222 331 -248 375 -13 22 -28 42 -32 43 -4 2 -8 9 -8 16 0
7 -12 31 -28 52 -32 45 -112 173 -112 179 0 3 -29 53 -64 112 -111 190 -242
433 -361 673 -68 137 -118 243 -225 485 -24 54 -50 116 -74 175 -15 36 -31 74
-36 85 -5 11 -19 45 -30 75 -11 30 -24 64 -29 75 -15 36 -39 99 -72 190 -18
50 -36 95 -40 100 -3 6 -10 26 -14 45 -4 19 -11 41 -16 50 -5 8 -24 65 -43
125 -20 61 -53 166 -76 234 -22 67 -40 131 -40 140 0 10 -3 21 -7 25 -5 4 -13
29 -20 54 -6 26 -19 73 -28 104 -35 128 -104 392 -115 443 -5 22 -16 72 -25
110 -111 493 -195 1038 -230 1490 -7 99 -19 245 -25 325 -15 185 -15 1157 -1
1345 34 442 42 531 55 645 30 256 72 535 111 735 8 44 22 114 30 155 8 41 21
107 29 145 8 39 20 97 26 130 6 33 15 69 20 80 5 11 11 38 15 60 3 22 10 54
15 70 5 17 16 59 25 95 26 100 45 170 61 224 7 26 14 56 14 65 0 10 3 21 7 25
5 4 13 29 20 54 13 55 31 115 68 232 15 47 33 105 41 130 32 107 202 576 244
675 9 22 23 56 30 75 7 19 23 58 35 85 12 28 42 95 65 150 23 55 55 128 71
163 16 34 43 92 60 130 38 85 245 497 288 577 37 67 75 136 123 223 38 69 300
501 327 540 11 15 53 79 94 142 102 157 311 453 385 545 4 6 37 48 72 95 95
126 370 459 475 576 6 6 28 31 49 55 277 308 607 639 886 889 52 47 111 99
130 117 29 25 245 206 325 272 152 124 473 364 635 476 278 192 596 396 740
477 25 14 97 54 160 90 63 36 144 82 180 102 73 40 558 282 635 316 28 12 83
38 124 57 40 19 144 63 230 99 86 36 170 71 186 78 305 133 887 327 1300 435
66 17 136 36 155 41 19 5 76 19 125 30 50 11 106 24 125 30 19 5 71 16 115 25
44 9 96 20 115 25 19 5 64 14 100 21 36 6 99 17 140 25 41 8 104 18 140 24 36
5 88 14 115 19 68 13 296 45 590 81 212 26 1125 72 1255 63 30 -2 204 -8 387
-13z"/>
<path d="M15675 27150 c-753 -25 -1450 -120 -2150 -290 -726 -177 -1355 -402
-2055 -736 -1700 -808 -3164 -2077 -4217 -3655 -665 -996 -1173 -2141 -1457
-3284 -66 -265 -85 -353 -150 -690 -44 -227 -94 -597 -116 -855 -6 -63 -15
-164 -20 -225 -35 -378 -38 -1689 -5 -2105 41 -522 92 -909 175 -1320 41 -203
51 -249 81 -370 390 -1622 1128 -3094 2178 -4345 1129 -1346 2577 -2392 4196
-3030 885 -349 1758 -569 2652 -670 525 -59 737 -68 1523 -68 739 0 944 8
1385 54 1618 167 3199 722 4580 1607 1227 787 2263 1802 3069 3006 1019 1522
1633 3305 1756 5096 30 429 39 1272 20 1750 -48 1172 -266 2253 -670 3320
-736 1941 -2006 3604 -3680 4816 -1101 797 -2354 1378 -3664 1699 -610 149
-1153 231 -1851 280 -257 18 -1223 27 -1580 15z m1195 -310 c47 -5 141 -12
210 -15 133 -6 386 -29 510 -46 41 -6 122 -17 180 -24 158 -21 414 -64 570
-96 58 -11 134 -27 170 -34 166 -33 509 -118 720 -180 69 -20 141 -41 160 -47
19 -5 127 -41 240 -79 297 -99 294 -98 310 -108 9 -5 30 -12 48 -15 17 -4 34
-12 37 -17 4 -5 13 -9 21 -9 12 0 144 -52 229 -90 11 -5 65 -27 120 -50 55
-23 109 -45 120 -50 26 -12 133 -60 220 -100 39 -17 158 -75 265 -128 180 -89
239 -120 435 -227 106 -58 448 -264 550 -331 50 -32 98 -63 107 -69 16 -9 174
-117 278 -190 173 -122 441 -323 550 -415 30 -25 111 -93 180 -150 540 -450
1146 -1087 1571 -1650 10 -14 28 -37 39 -52 163 -216 396 -552 502 -727 18
-29 40 -64 48 -76 27 -43 207 -349 241 -410 18 -33 50 -91 71 -130 41 -76 257
-510 306 -615 16 -36 57 -130 91 -210 34 -80 66 -154 71 -165 5 -11 21 -49 35
-85 14 -36 30 -73 35 -82 6 -10 10 -23 10 -30 0 -7 6 -24 13 -38 7 -14 23 -56
36 -95 13 -38 29 -83 37 -100 15 -33 129 -378 164 -495 12 -41 28 -93 35 -115
8 -22 17 -56 21 -75 3 -19 10 -41 15 -50 4 -8 13 -35 18 -60 5 -25 19 -81 31
-125 12 -44 29 -107 37 -140 8 -33 21 -87 29 -120 8 -33 21 -91 29 -130 8 -38
24 -115 36 -170 64 -300 135 -785 159 -1090 6 -71 15 -170 21 -220 31 -266 30
-1321 -1 -1566 -5 -46 -15 -145 -20 -219 -13 -178 -62 -578 -75 -620 -3 -9
-12 -61 -20 -116 -24 -163 -39 -240 -95 -504 -29 -137 -67 -294 -100 -415 -12
-44 -26 -100 -31 -125 -5 -25 -14 -52 -19 -61 -5 -9 -12 -34 -16 -55 -4 -22
-12 -55 -19 -74 -7 -19 -23 -69 -35 -110 -34 -112 -149 -462 -160 -485 -5 -11
-29 -74 -54 -140 -24 -66 -49 -131 -54 -145 -6 -14 -20 -50 -32 -80 -11 -30
-25 -64 -30 -75 -5 -11 -34 -81 -66 -155 -31 -74 -59 -139 -63 -145 -4 -5 -27
-55 -51 -110 -69 -157 -290 -589 -369 -723 -17 -29 -31 -55 -31 -57 0 -17
-314 -527 -410 -665 -9 -14 -43 -63 -75 -110 -85 -124 -346 -482 -365 -500 -3
-3 -39 -48 -80 -100 -80 -102 -274 -333 -349 -415 -205 -226 -269 -293 -436
-460 -180 -180 -340 -331 -450 -425 -27 -24 -52 -46 -55 -49 -3 -4 -54 -49
-115 -100 -60 -51 -121 -103 -135 -115 -32 -27 -270 -213 -375 -292 -238 -180
-552 -392 -835 -565 -133 -82 -142 -87 -160 -96 -8 -4 -37 -20 -65 -36 -162
-96 -378 -212 -590 -317 -133 -66 -245 -120 -248 -120 -3 0 -47 -20 -99 -43
-51 -24 -124 -56 -162 -72 -38 -15 -77 -31 -85 -35 -9 -5 -59 -25 -111 -46
-52 -20 -107 -42 -122 -49 -15 -8 -41 -16 -58 -20 -16 -4 -32 -11 -35 -16 -4
-5 -14 -9 -23 -9 -9 0 -30 -7 -45 -15 -15 -8 -42 -19 -60 -24 -18 -5 -104 -35
-192 -66 -88 -31 -176 -61 -195 -66 -19 -6 -44 -14 -55 -19 -22 -9 -112 -38
-160 -51 -16 -4 -64 -17 -105 -29 -261 -75 -453 -123 -650 -164 -303 -64 -368
-76 -525 -100 -49 -8 -101 -17 -115 -20 -72 -18 -433 -60 -695 -81 -99 -8
-238 -19 -310 -25 -169 -13 -1052 -13 -1220 0 -71 6 -211 17 -310 25 -262 21
-623 63 -695 81 -14 3 -65 12 -115 20 -157 24 -222 36 -525 100 -133 27 -281
63 -395 94 -44 12 -100 26 -125 31 -25 5 -52 14 -61 19 -9 5 -34 12 -55 16
-22 4 -55 12 -74 19 -19 7 -69 23 -110 35 -41 12 -127 40 -190 62 -63 22 -131
44 -150 49 -19 6 -44 14 -55 19 -11 4 -67 25 -125 45 -58 21 -118 43 -135 50
-66 27 -173 69 -215 85 -63 24 -102 39 -125 50 -11 5 -69 30 -130 56 -60 25
-146 63 -190 84 -44 21 -102 48 -130 60 -122 56 -504 250 -530 270 -5 4 -37
22 -70 40 -49 26 -168 96 -403 235 -20 12 -46 28 -177 114 -55 35 -125 80
-155 100 -30 19 -57 38 -60 41 -3 3 -32 23 -65 45 -33 22 -79 53 -101 70 -23
16 -52 37 -64 45 -11 8 -63 46 -113 83 -51 37 -103 76 -116 85 -13 9 -28 22
-35 27 -6 6 -36 29 -66 53 -30 23 -62 47 -70 54 -8 7 -38 32 -68 55 -76 59
-142 114 -312 262 -285 247 -695 653 -935 925 -39 44 -77 87 -85 96 -8 9 -48
56 -89 105 -41 50 -80 95 -86 100 -5 6 -35 42 -65 80 -30 39 -60 77 -67 85 -7
8 -32 40 -56 70 -23 30 -46 60 -52 66 -5 6 -17 21 -25 33 -8 13 -46 64 -85
116 -38 51 -77 103 -85 115 -8 12 -25 36 -38 53 -13 18 -53 77 -90 132 -37 55
-70 105 -75 110 -5 6 -32 48 -62 95 -30 47 -58 90 -63 96 -5 5 -15 21 -22 35
-7 13 -45 76 -85 139 -39 63 -84 138 -100 165 -166 293 -216 385 -326 605 -59
118 -119 242 -134 275 -14 33 -37 83 -50 110 -30 63 -127 292 -172 405 -18 47
-37 94 -42 105 -10 22 -56 146 -77 210 -8 22 -18 47 -22 55 -4 8 -34 96 -66
195 -32 99 -62 189 -66 200 -5 11 -18 54 -30 95 -12 41 -26 89 -32 105 -5 17
-16 54 -24 83 -8 28 -21 76 -29 105 -20 73 -53 200 -71 277 -38 159 -45 191
-65 285 -82 395 -124 648 -164 1000 -14 128 -22 208 -47 515 -18 212 -18 1159
0 1375 33 408 45 526 76 760 21 156 70 443 100 590 8 39 21 106 30 150 18 91
42 194 56 245 6 19 24 91 41 160 16 69 37 149 45 178 36 134 45 164 78 272 41
139 153 477 166 505 5 11 16 38 23 60 25 71 69 189 78 210 5 11 24 58 42 105
42 105 111 270 139 330 11 25 29 63 38 85 63 144 287 596 350 705 50 88 82
143 104 183 64 115 81 145 113 195 18 29 33 56 33 61 0 5 4 11 8 13 5 2 28 37
53 78 24 41 47 80 52 85 21 26 117 178 117 185 0 5 3 10 8 12 4 2 40 53 81
113 85 126 121 176 174 245 20 27 37 54 37 60 0 5 4 10 9 10 4 0 28 28 52 63
23 34 46 64 49 67 4 3 22 25 41 50 140 184 407 499 589 695 95 103 471 478
575 575 87 82 347 309 435 380 30 25 66 55 80 67 28 25 390 299 494 375 77 57
253 177 386 264 52 35 100 68 107 73 7 6 14 11 17 11 3 0 55 32 117 70 61 39
113 70 116 70 3 0 25 14 50 30 24 17 46 30 49 30 3 0 46 24 97 54 160 94 619
330 777 399 19 8 71 32 115 52 115 52 107 49 255 110 74 31 144 60 155 65 73
33 384 150 399 150 4 0 22 6 39 14 31 14 275 95 370 122 26 8 71 21 100 30
258 77 604 164 787 198 41 8 82 17 90 20 34 13 466 87 650 111 58 7 139 18
180 24 218 30 630 61 950 71 262 8 784 2 900 -10z"/>
<path d="M14816 24208 c-14 -34 -303 -656 -434 -933 -41 -88 -87 -187 -102
-220 -15 -33 -49 -105 -75 -160 -26 -55 -87 -185 -135 -290 -48 -104 -141
-302 -205 -440 -117 -250 -245 -523 -420 -900 -51 -110 -111 -238 -133 -285
-129 -276 -399 -854 -422 -905 -30 -66 -700 -1502 -1142 -2450 -154 -330 -354
-757 -443 -950 -90 -192 -184 -395 -210 -450 -26 -55 -134 -287 -240 -515
-241 -519 -506 -1084 -509 -1088 -8 -7 -217 78 -335 138 -244 122 -523 335
-647 492 -25 32 -51 63 -58 70 -7 7 -41 56 -76 108 -77 115 -169 297 -204 405
-15 44 -30 89 -35 100 -11 23 -50 215 -62 295 -21 157 -27 354 -14 517 18 233
9 248 -23 38 -67 -439 -58 -820 28 -1158 158 -625 625 -1125 1306 -1398 292
-117 608 -192 1005 -240 251 -31 887 -34 1159 -6 740 76 1336 202 2075 439
1605 514 3239 1416 4629 2558 1660 1363 2796 2958 3065 4305 46 230 55 329 55
615 0 260 -2 282 -28 410 -93 461 -243 756 -530 1044 -353 354 -748 555 -1398
711 -177 42 -373 78 -396 72 -9 -2 1 -7 23 -11 39 -7 115 -28 245 -66 36 -10
99 -28 140 -40 41 -11 95 -29 120 -40 25 -10 88 -35 140 -55 52 -20 111 -45
130 -56 19 -11 62 -33 95 -48 33 -15 74 -39 90 -53 17 -13 46 -33 65 -44 19
-10 64 -42 100 -69 36 -28 79 -59 95 -69 17 -11 92 -82 168 -160 167 -171 257
-302 340 -492 55 -129 68 -164 101 -274 94 -320 103 -798 22 -1175 -22 -107
-39 -180 -42 -184 -2 -2 -65 38 -140 89 -326 220 -679 352 -1104 412 -124 17
-476 17 -600 0 -215 -31 -428 -83 -600 -149 -111 -42 -350 -163 -464 -234 -44
-27 -83 -49 -86 -49 -4 0 -46 84 -94 188 -781 1676 -1027 2199 -1063 2260 -24
40 -78 146 -120 235 -42 89 -80 168 -86 175 -7 9 -271 12 -1258 12 l-1249 0
-14 -32z m1326 -2718 c230 -495 1903 -4082 2004 -4299 88 -188 112 -247 103
-257 -10 -13 -123 -96 -278 -206 -69 -50 -487 -330 -581 -390 -77 -50 -228
-144 -270 -168 -19 -12 -53 -32 -75 -45 -22 -13 -59 -34 -82 -46 -24 -13 -43
-27 -43 -31 0 -4 -4 -8 -8 -8 -5 0 -62 -31 -128 -68 -65 -37 -155 -88 -199
-111 -44 -24 -100 -55 -125 -69 -78 -43 -642 -326 -715 -359 -38 -17 -101 -46
-140 -63 -38 -18 -117 -52 -175 -77 -58 -24 -118 -50 -135 -58 -44 -20 -201
-84 -270 -110 -33 -12 -71 -27 -85 -33 -26 -11 -161 -62 -305 -114 -121 -43
-384 -130 -525 -172 -69 -21 -134 -41 -145 -46 -11 -4 -49 -15 -85 -24 -36 -9
-108 -28 -160 -42 -52 -14 -133 -34 -180 -45 -47 -11 -110 -26 -140 -34 -30
-7 -122 -27 -205 -43 -82 -17 -177 -35 -210 -42 -33 -7 -90 -16 -126 -20 -37
-5 -92 -13 -124 -19 -32 -6 -60 -9 -62 -8 -3 3 57 135 184 407 56 118 449 962
483 1035 35 76 413 885 558 1195 124 265 615 1320 1177 2525 662 1421 937
2010 947 2028 5 10 14 17 19 15 5 -2 51 -91 101 -198z m4193 -115 c205 -19
304 -41 521 -115 221 -75 465 -218 662 -387 l32 -28 -30 -75 c-17 -41 -37 -89
-45 -107 -8 -17 -15 -35 -15 -41 0 -5 -6 -14 -13 -20 -9 -8 -25 1 -62 37 -28
26 -86 74 -130 107 -44 32 -82 61 -85 65 -11 12 -129 79 -215 122 -99 49 -227
97 -320 120 -33 9 -79 20 -102 26 -68 18 -280 41 -383 41 -99 0 -302 -22 -380
-40 -204 -50 -285 -78 -445 -159 -71 -37 -170 -95 -219 -129 -48 -35 -94 -66
-100 -68 -6 -2 -23 22 -37 53 -15 32 -38 84 -53 115 l-26 57 33 31 c17 17 71
55 118 85 284 177 614 287 944 314 147 12 185 12 350 -4z m78 -716 c260 -54
463 -154 667 -331 95 -81 120 -98 149 -98 11 0 22 -4 26 -9 6 -10 -121 -238
-218 -391 -160 -252 -310 -465 -538 -760 -50 -65 -237 -291 -253 -305 -6 -5
-36 -39 -66 -75 -101 -119 -149 -165 -160 -154 -14 14 -550 1164 -550 1181 0
9 14 13 44 13 61 0 99 19 186 90 141 116 271 164 445 165 188 0 340 -56 466
-174 70 -65 118 -85 196 -78 50 4 75 12 107 34 83 57 115 180 69 269 -45 85
-230 226 -391 298 -34 15 -106 39 -160 53 -88 24 -114 26 -282 26 -167 0 -195
-3 -285 -26 -175 -46 -378 -163 -508 -292 l-47 -47 -55 117 c-30 64 -55 121
-55 127 0 33 204 184 340 253 82 40 228 89 345 114 133 29 390 29 528 0z"/>
<path d="M20074 19660 c-97 -20 -201 -96 -244 -181 -12 -23 -28 -71 -35 -108
-12 -56 -12 -76 0 -132 18 -88 54 -157 106 -203 149 -135 390 -126 517 18 236
269 5 675 -344 606z"/>
<path d="M22783 22288 c29 -116 51 -413 43 -583 -14 -314 -70 -601 -189 -966
-120 -365 -291 -731 -540 -1154 -81 -137 -322 -498 -417 -627 -36 -47 -74 -99
-85 -114 -22 -31 -253 -322 -294 -370 -14 -17 -44 -52 -66 -79 -113 -139 -553
-612 -712 -766 -284 -275 -601 -566 -712 -655 -13 -10 -59 -49 -102 -86 -63
-53 -261 -217 -304 -251 -103 -81 -238 -187 -261 -204 -347 -259 -589 -435
-608 -442 -6 -2 -13 -7 -16 -11 -7 -9 -374 -255 -539 -360 -73 -47 -167 -106
-207 -132 -41 -27 -77 -48 -79 -48 -2 0 -51 -28 -107 -63 -97 -60 -252 -150
-356 -208 -26 -14 -63 -35 -82 -46 -122 -69 -297 -164 -400 -216 -36 -17 -67
-34 -70 -37 -7 -7 -463 -233 -530 -263 -30 -13 -93 -42 -140 -65 -124 -58
-183 -85 -290 -131 -52 -23 -106 -47 -120 -53 -14 -6 -34 -14 -45 -19 -11 -4
-58 -24 -105 -44 -47 -20 -121 -49 -165 -66 -44 -17 -89 -34 -100 -39 -25 -11
-249 -97 -290 -111 -36 -13 -62 -23 -95 -37 -14 -5 -54 -20 -90 -32 -36 -11
-74 -25 -85 -30 -25 -10 -195 -66 -335 -110 -58 -18 -114 -36 -125 -41 -30
-13 -424 -123 -585 -164 -111 -29 -347 -84 -405 -95 -33 -6 -69 -15 -80 -20
-11 -5 -38 -11 -60 -14 -44 -5 -277 -47 -395 -71 -92 -18 -448 -65 -585 -76
-55 -4 -147 -13 -205 -18 -183 -18 -660 -25 -865 -12 -367 22 -685 70 -1004
152 -41 10 -82 19 -90 19 -21 -1 295 -161 394 -200 349 -138 692 -217 1156
-266 205 -22 795 -25 1034 -6 1185 95 2428 440 3780 1048 208 93 800 392 1040
524 464 256 933 550 1479 926 60 41 113 73 117 72 9 -3 59 -106 229 -473 90
-196 139 -301 216 -465 25 -52 147 -315 273 -585 l228 -490 1222 -3 c707 -1
1221 2 1221 7 0 5 -40 94 -89 197 -49 104 -258 554 -466 999 -208 446 -424
909 -480 1030 -56 121 -211 453 -344 737 -132 285 -241 527 -241 539 0 13 41
63 108 132 248 256 441 472 654 732 855 1044 1381 2101 1503 3025 22 161 30
508 16 652 -14 140 -50 322 -92 467 -17 58 -36 123 -41 144 -11 39 -34 53 -25
15z"/>
<path d="M12208 13553 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M21920 12642 l-415 -198 0 -114 c1 -89 6 -134 24 -198 l23 -82 52 6
c105 14 101 10 104 101 2 61 7 84 20 96 15 13 82 46 497 242 403 190 362 175
458 175 l87 0 0 85 0 85 -217 0 -218 -1 -415 -197z"/>
<path d="M9765 12788 c-8 -24 -148 -479 -294 -953 -43 -143 -136 -444 -206
-670 -70 -225 -151 -489 -181 -585 -29 -96 -65 -211 -78 -255 -14 -44 -26 -86
-26 -93 0 -10 30 -12 132 -10 l131 3 119 385 c65 212 140 457 167 545 92 297
160 518 305 989 79 258 150 472 157 477 8 5 155 9 327 9 l312 0 -6 26 c-4 15
-14 52 -23 83 l-17 56 -407 3 c-321 2 -408 0 -412 -10z"/>
<path d="M10760 12789 c0 -17 80 -159 90 -159 20 0 39 -33 60 -102 20 -66 46
-120 170 -353 21 -38 62 -117 93 -175 30 -58 134 -255 231 -437 l176 -333 0
-505 0 -505 100 0 100 0 0 377 0 377 -114 215 c-62 119 -249 473 -415 788
-165 314 -301 580 -301 591 0 10 8 26 18 35 16 15 63 17 415 19 l396 3 -37 85
-38 85 -472 3 c-347 2 -472 -1 -472 -9z"/>
<path d="M12540 12783 c-6 -10 -80 -166 -165 -346 l-153 -328 68 -157 c38 -86
72 -158 77 -159 4 -2 93 179 197 402 104 222 194 409 200 415 6 6 64 10 141
10 l130 0 43 79 c23 43 42 84 42 90 0 8 -80 11 -285 11 -261 0 -286 -1 -295
-17z"/>
<path d="M13940 11510 l0 -1290 95 0 95 0 2 1185 c3 1091 4 1186 20 1201 15
15 68 17 585 18 595 1 639 -1 802 -45 40 -11 76 -18 78 -15 3 3 -24 26 -59 52
-123 91 -277 149 -467 173 -52 7 -291 11 -618 11 l-533 0 0 -1290z"/>
<path d="M16596 12713 c-15 -49 -54 -173 -86 -278 -32 -104 -101 -328 -152
-496 l-95 -305 9 -75 c14 -107 4 -426 -17 -539 -33 -188 -115 -390 -209 -518
-32 -43 -46 -72 -46 -94 0 -27 -13 -46 -77 -110 l-78 -78 128 0 c70 0 127 2
127 5 0 6 312 1022 445 1450 24 77 59 192 78 255 142 467 210 677 224 688 13
9 94 12 325 12 203 0 308 3 308 10 0 6 -10 44 -22 85 l-22 75 -406 0 -407 0
-27 -87z"/>
<path d="M17800 12602 l0 -198 49 -173 c27 -95 51 -175 54 -177 2 -3 25 5 51
18 l46 22 0 248 c0 235 1 249 20 268 20 20 33 20 955 20 l935 0 0 85 0 85
-1055 0 -1055 0 0 -198z"/>
<path d="M20249 12713 l-25 -88 6 -410 5 -410 42 130 c32 100 167 538 199 647
14 48 17 48 346 48 l311 0 -7 30 c-3 17 -13 55 -22 85 l-15 55 -408 0 -408 0
-24 -87z"/>
<path d="M22180 12411 l-415 -197 -3 -194 c-1 -106 1 -197 6 -202 4 -4 106 8
227 27 120 20 222 32 227 27 4 -4 9 -431 10 -947 l3 -940 390 0 390 0 3 1313
2 1312 -212 -1 -213 0 -415 -198z"/>
<path d="M9661 11388 c-201 -651 -379 -1230 -398 -1288 -18 -58 -33 -108 -33
-112 0 -5 146 -8 324 -8 301 0 325 1 330 18 3 9 46 195 96 412 50 217 93 403
96 413 5 15 24 17 219 17 l214 0 11 -37 c5 -21 41 -173 80 -338 38 -165 80
-340 92 -390 l23 -90 428 -3 c235 -1 427 0 427 4 0 3 -51 186 -114 407 -300
1053 -586 2058 -601 2115 l-18 62 -406 0 -407 0 -363 -1182z m683 126 c24 -98
47 -194 51 -211 l7 -33 -101 0 c-86 0 -101 2 -101 16 0 9 20 103 45 211 25
107 45 197 45 200 0 25 16 -28 54 -183z"/>
<path d="M10282 11398 c-17 -65 -16 -68 18 -68 28 0 30 2 24 28 -4 15 -12 40
-19 57 l-11 30 -12 -47z"/>
<path d="M14203 12564 c-10 -5 -13 -272 -13 -1289 0 -706 3 -1286 8 -1288 4
-2 223 0 487 4 503 7 563 12 735 59 150 42 312 144 450 285 63 64 64 65 28 50
-21 -8 -40 -15 -44 -15 -4 0 18 26 48 58 153 161 249 357 296 606 24 130 24
512 -1 646 -60 321 -222 599 -427 735 -90 59 -175 93 -310 123 -114 25 -118
25 -680 28 -311 2 -571 1 -577 -2z m987 -507 c87 -44 157 -186 196 -395 22
-116 30 -447 15 -610 -36 -384 -137 -547 -358 -577 l-53 -7 0 813 0 812 78 -7
c50 -4 93 -14 122 -29z"/>
<path d="M15110 12013 c0 -5 9 -58 20 -118 16 -89 20 -152 20 -350 0 -322 -23
-490 -86 -634 -23 -51 -24 -64 -22 -215 l3 -161 30 3 c17 1 50 13 75 25 83 42
140 153 176 342 21 111 30 543 14 672 -23 187 -77 337 -142 400 -32 31 -88 54
-88 36z"/>
<path d="M16875 12553 c-4 -10 -33 -103 -65 -208 -32 -104 -106 -345 -165
-535 -109 -352 -196 -636 -265 -860 -21 -69 -95 -309 -164 -533 -69 -225 -126
-415 -126 -423 0 -12 48 -14 322 -12 l322 3 26 110 c38 156 81 341 131 558
l43 187 216 0 216 0 12 -47 c6 -27 18 -77 26 -113 9 -36 26 -112 40 -170 14
-58 46 -195 71 -305 26 -110 51 -206 57 -212 16 -19 848 -19 848 0 0 7 -32
125 -71 263 -39 137 -181 636 -315 1109 -135 473 -261 916 -280 985 -20 69
-42 146 -51 173 l-15 47 -403 0 c-374 0 -403 -1 -410 -17z m328 -1055 c26
-106 47 -201 47 -210 0 -16 -11 -18 -100 -18 -55 0 -100 3 -100 6 0 3 18 83
39 177 22 95 42 187 46 205 4 17 10 32 14 32 3 0 28 -87 54 -192z"/>
<path d="M17142 11405 c-18 -61 -16 -75 13 -75 29 0 30 13 11 75 l-13 40 -11
-40z"/>
<path d="M18060 12170 c0 -220 3 -400 6 -400 4 0 148 72 321 160 172 88 318
160 323 160 6 0 11 -389 12 -1052 l3 -1053 393 -3 392 -2 0 1055 c0 580 3
1055 6 1055 4 0 150 -72 325 -160 175 -88 320 -160 323 -160 3 0 6 180 6 400
l0 400 -1055 0 -1055 0 0 -400z"/>
<path d="M20505 12483 c-14 -49 -37 -122 -50 -163 -21 -64 -269 -870 -555
-1800 -45 -146 -99 -322 -121 -392 -21 -69 -39 -131 -39 -137 0 -14 636 -16
645 -1 5 8 74 295 120 500 8 36 30 128 48 205 l33 140 216 3 c200 2 217 1 222
-15 6 -22 153 -651 172 -741 25 -113 -26 -102 454 -102 398 0 420 1 420 18 0
10 -47 182 -104 383 -57 200 -201 708 -321 1129 -120 421 -236 830 -259 910
l-41 145 -407 3 -407 2 -26 -87z m360 -1020 c25 -102 45 -187 45 -189 0 -2
-48 -4 -106 -4 -96 0 -105 2 -100 18 3 9 23 98 46 197 22 99 43 187 46 195 9
22 18 -7 69 -217z"/>
<path d="M20797 11414 c-3 -18 -9 -43 -13 -58 -6 -24 -4 -26 25 -26 29 0 31 2
25 28 -4 15 -12 41 -19 57 l-11 30 -7 -31z"/>
<path d="M11066 12453 c31 -60 78 -148 104 -198 26 -49 151 -286 277 -525 237
-448 350 -663 377 -715 14 -26 16 -97 16 -532 l0 -503 385 0 385 0 0 542 0
541 136 266 c75 146 167 325 204 396 37 72 111 216 165 320 54 105 136 263
182 353 l84 162 -293 0 -293 -1 -37 -82 c-38 -82 -77 -167 -221 -472 -41 -88
-96 -205 -121 -259 -27 -59 -50 -96 -55 -90 -5 5 -80 171 -166 369 -86 198
-174 398 -195 445 l-38 85 -477 3 -477 2 58 -107z"/>
<path d="M18548 11944 l-88 -44 0 -840 0 -840 100 0 100 0 0 885 c0 702 -3
885 -12 884 -7 0 -52 -20 -100 -45z"/>
<path d="M22080 11799 c-25 -4 -60 -11 -77 -14 l-33 -6 0 -596 0 -596 52 -184
52 -183 48 0 48 0 0 795 0 795 -22 -1 c-13 -1 -43 -5 -68 -10z"/>
<path d="M19941 11718 c-12 -40 -40 -129 -61 -198 -21 -69 -68 -222 -105 -340
-37 -118 -98 -318 -136 -443 l-69 -229 0 -144 0 -145 91 3 91 3 228 740 228
740 -37 6 c-20 3 -69 22 -108 42 -40 20 -79 37 -86 37 -8 0 -23 -29 -36 -72z"/>
<path d="M10332 10758 c3 -13 31 -139 64 -280 l59 -258 71 0 71 0 -54 228
c-29 125 -59 251 -65 280 l-11 52 -70 0 c-67 0 -69 -1 -65 -22z"/>
<path d="M17180 10776 c0 -4 26 -112 102 -428 l30 -128 70 0 71 0 -7 28 c-3
15 -32 140 -64 277 l-58 250 -72 3 c-40 2 -72 1 -72 -2z"/>
<path d="M20845 10723 c8 -32 32 -139 56 -238 23 -99 46 -199 51 -222 l10 -43
69 0 c48 0 69 4 69 13 0 6 -28 132 -62 280 l-62 267 -72 0 -72 0 13 -57z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
